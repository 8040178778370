import { Component, Input } from '@angular/core';
import { Step } from '@fc-lib';
import { BaseComponent } from '../base/base.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { StudentEmergencyContact } from '../../models/student-create.model';

@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrl: './emergency-contact.component.scss'
})
export class EmergencyContactComponent extends BaseComponent {
  @Input() emergencyContact: StudentEmergencyContact | undefined;
  steps: Step[] = [
    { title: 'Your details', currentStep: false, completed: true },
    { title: 'Emergency contacts', currentStep: true, completed: false },
    { title: 'Sign a waiver', currentStep: false, completed: false },
  ]
  submitted = false;

  get disableNext() {
    return !this.emergencyContactForm.valid;
  }

  emergencyContactForm = this.fb.group({
    emergencyContactFirstName: new FormControl<string>('', Validators.required),
    emergencyContactLastName: new FormControl<string>('', Validators.required),
    emergencyContactRelationship: new FormControl<string>('', Validators.required),
    emergencyContactMobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)]),
  })

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    if (this.emergencyContact) {
      this.emergencyContactForm.patchValue(this.emergencyContact);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.emergencyContactForm.valid) {
      this.onNext.emit(this.emergencyContactForm.value);
    }
  }
}

<div>
    <fc-text-input
        [label]="label"
        [type]="'text'"
        [required]="required"
        [matDatepicker]="datepicker"
        [placeholder]="placeholder"
        [submitted]="submitted"
        [formControl]="formControl"
        (click)="datepicker.open()"
        (dateChange)="onDateChange($event)"
    ></fc-text-input>
    <mat-datepicker touchUi #datepicker></mat-datepicker>
</div>

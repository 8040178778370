import { Component, Input, Optional, Self, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'fc-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css'],
})
export class TimeInputComponent implements ControlValueAccessor {
  @Input() submitted: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string | undefined;
  @Input() placeholder: string = '';
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() helper: string | undefined;
  @Input() inputClass: string = ''
  @Input() timePickerTheme: any = {
    clockFace: { clockHandColor: '#9E77ED' },
    dial: { dialBackgroundColor: '#9E77ED' },
    container: { buttonColor: '#9E77ED' },
  };
  @Input() defaultTime: string = '08:00';

  value: string = '';
  isDisabled: boolean = false;

  // Default error messages (used when no custom errorMessages are provided)
  defaultErrorMessages: { [key: string]: string } = {
    required: 'This field is required.',
    pattern: 'The input format is invalid.',
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(value: string): void {
    if (value){
      this.value = this.normalizeTimeFormat(value);
      this.defaultTime = this.convertFromMilitaryTime(this.value);
    }
    else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onTimeSet(time: string): void {
    this.value = this.convertToMilitaryTime(time);
    this.onChange(this.value);
    this.onTouched();
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

  get isInvalid(): boolean {
    return this.submitted && (this.ngControl?.invalid ?? false);
  }

  get inputClasses(): string[] {
    const baseClasses = [
      'w-[7rem]', 'border', 'focus:outline-0', 'focus:ring', 'px-4', 'py-3', 'rounded-lg', 'hover:bg-gray-50',
    ];

    if (this.isDisabled) {
      baseClasses.push('bg-gray-50', 'border-gray-300', 'text-gray-600');
    } else if (this.isInvalid) {
      baseClasses.push('border-error-300', 'focus:border-error-300', 'focus:ring-error-300');
    } else {
      baseClasses.push('border-gray-300', 'focus:border-primary-300', 'focus:ring-gray-100');
    }

    return [...baseClasses, ...this.inputClass.split(' ')];
  }

  convertToMilitaryTime(time: string): string {
    const [timeValue, meridian] = time.split(' ');
    let [hours, minutes] = timeValue.split(':').map(Number);

    if (meridian.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    } else if (meridian.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  convertFromMilitaryTime(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
  
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
  
    return `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  getMergedErrorMessages(): { [key: string]: string } {
    return { ...this.defaultErrorMessages, ...this.errorMessages };  // Custom messages override default
  }

  getControlErrors(): { [key: string]: any } | null {
    return this.ngControl?.control?.errors || null;
  }

  getErrorMessage(errorType: string): string | null {
    const mergedMessages = this.getMergedErrorMessages();
    return this.ngControl?.control?.hasError(errorType) ? mergedMessages[errorType] : null;
  }

  getActiveErrorMessages(): string[] {
    const errors = this.getControlErrors();
    const mergedMessages = this.getMergedErrorMessages();

    if (!errors) {
      return [];
    }

    // Use the control error message if it is a string (i.e we supplied it programatically) otherwise use the input or default message
    return Object.entries(errors).map(([errorType, errorValue]) => 
      typeof errorValue === 'string' ? errorValue : mergedMessages[errorType] || ''
    );
  }

  private normalizeTimeFormat(time: string): string {
    return time.includes(':') ? time.split(':').slice(0, 2).join(':') : time; // convert either HH:mm or HH:mm:ss to HH:mm
  }  
}

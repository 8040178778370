import { Component, Input, Self, Optional, ContentChild, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseOutlineIconComponent } from '@dimaslz/ng-heroicons/components/common/base-outline-icon.component';

@Component({
  selector: 'fc-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css'],
})
export class TextInputComponent implements ControlValueAccessor, AfterContentInit {
  @Input() submitted: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string | undefined;
  @Input() placeholder: string = '';
  @Input() icon: string | undefined;
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() helper: string | undefined;
  @Input() matDatepicker: MatDatepicker<any> | undefined;
  @Input() type: string = 'text';

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Date>> = new EventEmitter<MatDatepickerInputEvent<Date>>()

  value: string = '';
  isDisabled: boolean = false;

  // Default error messages (used when no custom errorMessages are provided)
  defaultErrorMessages: { [key: string]: string } = {
    required: 'This field is required.',
    minlength: 'The input is too short.',
    maxlength: 'The input is too long.',
    pattern: 'The input format is invalid.',
    duplicate: 'This value is already taken.',
    email: 'Please enter a valid email.'
  };

  @ContentChild('icon', { static: false }) iconComponent?: BaseOutlineIconComponent;

  hasIcon: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    this.hasIcon = !!this.iconComponent;

    // Apply default svgClass if not provided
    if (this.iconComponent && !this.iconComponent.svgClass) {
      this.iconComponent.svgClass = 'w-5 h-5 text-gray-500 dark:text-gray-400';
    }
  }

  get isInvalid(): boolean {
    return this.submitted && (this.ngControl?.invalid ?? false);
  }

  get inputClasses(): string[] {
    const baseClasses = [
      'w-full', 'border', 'focus:outline-0', 'focus:ring', 'px-4', 'py-3', 'rounded-lg', 'hover:bg-gray-50'
    ];

    if (this.isDisabled) {
      baseClasses.push('bg-gray-50', 'border-gray-300', 'text-gray-600');
    } else if (this.isInvalid) {
      baseClasses.push('border-error-300', 'focus:border-error-300', 'focus:ring-error-300');
    } 
    else {
      baseClasses.push('border-gray-300', 'focus:border-primary-300', 'focus:ring-gray-100');
    }

    baseClasses.push(this.hasIcon ? 'pl-10' : 'pl-4');

    return baseClasses;
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
    this.onTouched();
  }

  getMergedErrorMessages(): { [key: string]: string } {
    return { ...this.defaultErrorMessages, ...this.errorMessages };  // Custom messages override default
  }

  getControlErrors(): { [key: string]: any } | null {
    return this.ngControl?.control?.errors || null;
  }

  getErrorMessage(errorType: string): string | null {
    const mergedMessages = this.getMergedErrorMessages();
    return this.ngControl?.control?.hasError(errorType) ? mergedMessages[errorType] : null;
  }

  getActiveErrorMessages(): string[] {
    const errors = this.getControlErrors();
    const mergedMessages = this.getMergedErrorMessages();

    if (!errors) {
      return [];
    }

    // Use the control error message if it is a string (i.e we supplied it programatically) otherwise use the input or default message
    return Object.entries(errors).map(([errorType, errorValue]) => 
      typeof errorValue === 'string' ? errorValue : mergedMessages[errorType] || `Undefined error: ${errorType}`
    );
  }
}

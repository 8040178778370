<div class="w-full flex flex-col h-dvh">
    <div class="mx-[20%] mb-4">
        <fc-stepper [steps]="steps"></fc-stepper>
    </div>
    <form [formGroup]="signatureForm" class="flex justify-between grow">
        <div class="w-full flex flex-col gap-4 pt-4 px-8 justify-between">
            <div class="flex flex-col gap-4">
                <div class="font-semibold text-primary-700 text-center mt-4">Current as of {{waiverDetails?.waiverDate | date: 'dd MMM yyyy' }}</div>
                <div class="ql-editor" [innerHTML]="waiverDetails?.waiverContent"></div>
                <div class="flex flex-col gap-4 border-t border-gray-200">
                    <span class="self-start font-semibold text-primary-700 text-center mt-4">
                        {{guardianSignature ? studentName + ' is under 18, please have a legal guardian over the age of 18 sign this document' : 'Sign below'}}
                    </span>
                    <fc-text-input *ngIf="guardianSignature" class="grow" formControlName="signatureName" [label]="guardianSignature ? 'Legal guardian full name' : 'Full name'" [required]="true" [placeholder]="guardianSignature ? 'Legal guardian full name' : 'Full name'" [submitted]="submitted"></fc-text-input>
                    <label class="block text-sm font-medium text-gray-700">{{guardianSignature ? 'Legal guardian signature' : 'Signature'}} <span class="text-primary-700">*</span></label>
                    <app-signature-pad #signaturePad></app-signature-pad>
                    <div class="w-full sm:w-20 self-end">
                        <fc-button-secondary (click)="clear()">Clear</fc-button-secondary>
                    </div>
                    
                </div>
            </div>
            <app-footer (onBack)="onBack.emit()" (onNext)="sign()" class="justify-self-end pb-4" [disabled]="signaturePadComponent?.signaturePad?.isEmpty() ?? true" [loading]="submitting"></app-footer>
        </div>
    </form>
</div>
    
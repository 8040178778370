<div class="flex items-center gap-2">
    <input
        type="checkbox"
        [checked]="value"
        [disabled]="disabled"
        class="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-0 cursor-pointer 
            checked:border-primary-600 checked:text-purple-600"
        (change)="onCheckboxChange($event)"
    />
    <label class="text-gray-700 font-medium text-sm">
        {{ label }} <span *ngIf="required" class="text-primary-700">*</span>
    </label>
</div>
<div class="w-full border border-gray-300 rounded-lg overflow-y-hidden">
    <table class="w-full border-collapse">
        <!-- Table Header -->
        <thead class="bg-gray-50 text-gray-500 text-xs border-b">
            <tr>
                <th *ngFor="let column of columns" [ngClass]="column.classes" class="px-6 py-3 text-left font-medium">
                    {{ column.header }}
                </th>
                <th *ngIf="actionTemplate" class="px-6 py-3 text-right font-medium"></th>
            </tr>
        </thead>

        <!-- Table Body -->
        <tbody>
            <tr *ngFor="let row of data; let first = first" [class.border-t]="!first" class="even:bg-gray-50 text-gray-800 text-sm">
                <!-- Data Cells -->
                <td *ngFor="let column of columns" [ngClass]="column.classes" class="px-6 py-4">
                    @if (column.template) {
                        <ng-container
                            *ngTemplateOutlet="column.template; context: { $implicit: row }">
                        </ng-container>
                    }
                    @else if (column.formatter) {
                        <span>
                            {{ column.formatter(row) }}
                        </span>
                    }
                    @else if (column.field){
                        <span>
                            {{ row[column.field] }}
                        </span>
                    }
                    @else {
                        <span></span>
                    }
                </td>
            
                <!-- Actions -->
                <td *ngIf="actionTemplate" class="px-6 py-4">
                    <ng-container
                        *ngTemplateOutlet="actionTemplate; context: { $implicit: row }">
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-button-secondary',
  templateUrl: './button-secondary.component.html',
})
export class ButtonSecondaryComponent {
  @Input() variant: string = '';
  @Input() disabled = false;
  @Input() loading = false;

  get buttonClasses(): string[] {
    const variants = this.variant.split(' ');
    const baseClasses = [
      'disabled:bg-gray-200', 'disabled:text-gray-500', 'disabled:border-gray-300', 'border', 'shadow-sm', 'focus:ring', 'focus:ring-gray-100', 'focus:outline-0', 'font-semibold', 'rounded-lg', 'text-center', 'w-full', 'flex', 'items-center'
    ];

    // size
    if (variants.includes('large')) {
      baseClasses.push('px-4', 'py-2.5', 'font-semibold');
    }
    else {
      baseClasses.push('text-sm', 'px-3', 'py-2', 'font-medium', 'h-[30px]', 'sm:h-[38px]');
    }

    // color
    if (variants.includes('primary')) {
      baseClasses.push('bg-primary-50', 'hover:bg-primary-100', 'border-primary-300', 'text-primary-700')
    }
    else {
      baseClasses.push('bg-white', 'text-gray-700', 'border-gray-300', 'hover:bg-gray-50',)
    }
    
    
    return baseClasses;
  }
}

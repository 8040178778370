<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
        {{ label }} <span class="text-primary-700" *ngIf="required && !isDisabled">*</span>
    </label>

    <div class="relative flex items-center">
        <span class="absolute left-3 flex items-center h-ful text-gray-500">$</span>
        <input 
            type="number"
            [value]="value"
            [placeholder]="placeholder"
            [disabled]="isDisabled"
            class="border focus:outline-0 focus:ring pl-6 pr-4 py-3 rounded-lg w-full
                focus:border-primary-300 focus:ring-primary-100
                disabled:bg-gray-100 border-gray-300"
            [ngClass]="{'border-error-300 focus:border-error-300 focus:ring-error-100': this.isInvalid}"
            (input)="onInput($event)"
            (blur)="onBlur()"
        />
    </div>

    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{ helper }}</span>

    <ng-container *ngIf="submitted">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-error-500">{{ errorMessage }}</span>
    </ng-container>
</div>

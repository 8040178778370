<div class="h-full flex flex-col overflow-hidden" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
  <div class="flex flex-row justify-between mt-2 mb-4 ml-2">
    <div class="flex flex-row gap-3">
      <fc-button-secondary    
        (click)="onTodayClick()"
        variant="primary"
      >
        <span class="mx-1">Today</span>
      </fc-button-secondary>
      <fc-button-secondary class="hidden sm:block"
        (click)="onPrevClick()"
      >
        <chevron-left-outline-icon #icon/>
      </fc-button-secondary>
      <fc-button-secondary class="hidden sm:block"
        (click)="onNextClick()"
      >
        <chevron-right-outline-icon #icon/>
      </fc-button-secondary>

      <div class="text-gray-900 text-3xl"> 
        <span>{{title}}</span>
      </div>

    </div>
    <ng-content select="[fc-header-buttons]"></ng-content>
  </div>
  <full-calendar class="flex-grow" #calendar [options]="calendarOptions" [events]="events$ | async">
      <ng-template #eventContent let-arg>
        <div class="h-full overflow-y-hidden touch-action">
          <div class="fc-event-title-container">
              <div class="fc-event-title fc-sticky">{{arg.event.title}}</div>
          </div>
          <div class="fc-event-time" [class.line-through]="isDisabled(arg.event.id)">{{arg.timeText}}</div>
        </div>
      </ng-template>
      <ng-template #dayHeaderContent let-arg>
        <div class="flex flex-col items-center">
          <span [class.text-gray-700]="!arg.isToday" [class.text-primary-700]="arg.isToday" class="text-xs font-normal mb-1">{{formatDateHeaderDay(arg.date)}}</span>
          <div *ngIf="arg.isToday" class="text-center mb-1 flex justify-center items-center w-10 h-10 rounded-full bg-primary-700">
            <span class="text-center text-2xl font-semibold text-white">{{formatDateHeaderDate(arg.date)}}</span>
          </div>
          <div *ngIf="!arg.isToday" class="h-10 w-10 mb-1 flex justify-center items-center">
            <span class="text-2xl font-semibold text-gray-700">{{formatDateHeaderDate(arg.date)}}</span>
          </div>
        </div>
      </ng-template>
      <ng-template #slotLabelContent let-arg>
        <div class="h-full flex flex-col">
          <div class="relative text-xs font-normal">{{arg.text}}</div>
        </div>
      </ng-template>
  </full-calendar>
  <ng-content select="[fc-calendar-menu]"></ng-content>
</div>
import { AfterViewInit, ChangeDetectorRef, Component, effect, EventEmitter, Input, Output, signal, Signal, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import enAuLocale from '@fullcalendar/core/locales/en-au';
import * as moment from 'moment';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';

export enum CalendarViewType {
  Day = 'timeGridDay',
  Week = 'timeGridWeek',
}

@Component({
  selector: 'fc-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent implements AfterViewInit {
  @Input() initialView: CalendarViewType = CalendarViewType.Week;
  @Input() slotMinTime: string = '05:00:00'
  @Input() slotMaxTime: string = '23:00:00'

  @Input() events$!: BehaviorSubject<any[]>;

  @Output() dateSetChanged = new EventEmitter<DatesSetArg>();
  @Output() eventClicked = new EventEmitter<EventClickArg>();
  @Output() dateClicked = new EventEmitter<DateClickArg>();
  @Output() menuPositionChanged = new EventEmitter<{ box: DOMRect, row: DOMRect, date: Date }>();

  @Input({ required: true }) viewChangeTrigger: Signal<CalendarViewType> = signal(CalendarViewType.Week);
  @Input({ required: false }) resizeTrigger$?: Observable<void>;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  calendarOptions: CalendarOptions = {
    height: '100%',
    initialView: this.initialView,
    allDaySlot: false,
    expandRows: true,
    slotMinTime: this.slotMinTime,
    slotMaxTime: this.slotMaxTime,
    plugins: [timeGridPlugin, interactionPlugin],
    locale: enAuLocale,
    datesSet: this.onDateSetChanges.bind(this),
    eventClick: this.onEventClick.bind(this),
    dateClick: this.onDateClick.bind(this),
    eventClassNames: ['cursor-pointer'],
    buttonText: {
      today: 'Today'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    headerToolbar: false
  };

  constructor(private ref: ChangeDetectorRef) {
    effect(() => {
      if (this.calendarComponent) {
        this.calendarComponent.getApi()?.changeView(this.viewChangeTrigger());
      }
    });
  }
  
  ngAfterViewInit(): void {
    if (this.resizeTrigger$) {
      this.resizeTrigger$.pipe(debounceTime(250)).subscribe(() => {
        this.calendarComponent?.getApi()?.updateSize();
      });
    }

    this.ref.detectChanges();
  }

  onDateSetChanges(event: DatesSetArg) {
    this.dateSetChanged.emit(event);
  }

  onEventClick(event: EventClickArg) {
    this.eventClicked.emit(event);
  }

  onDateClick(event: DateClickArg) {
    this.dateClicked.emit(event);
  } 

  isDisabled(id: number) {
    const events = this.events$.value;

    return (events.find(x => x.id == id)?.isDisabled ?? false);
  }

  formatDateHeaderDay(date: string) {
    return moment(date).format('ddd').toUpperCase();
  }

  formatDateHeaderDate(date: string) {
    return moment(date).format('DD');
  }

  onSwipeLeft() {
    this.calendarComponent?.getApi().next();
  }

  onSwipeRight() {
    this.calendarComponent?.getApi().prev();
  }

  onNextClick() {
    this.calendarComponent?.getApi().next();
  }

  onPrevClick() {
    this.calendarComponent?.getApi().prev()
  }

  onTodayClick() {
    this.calendarComponent?.getApi().today();
  }

  get title() {
    return this.calendarComponent?.getApi().view.title;
  }
}

<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
        {{ label }}
        <span class="text-primary-700" *ngIf="required">*</span>
    </label>
    <div class="flex items-center gap-4">
        <mat-select [ngClass]="inputClasses" [disabled]="isDisabled" [multiple]="multiple" [value]="currentValue"
            [hideSingleSelectionIndicator]="hideSingleSelectionIndicator" (selectionChange)="onSelectionChange($event)"
            [placeholder]="placeholder"
            >
            <mat-select-trigger *ngIf="multiple">
                <span class="pl-4" [class]="textClasses">
                    {{ selectedLabels?.join(', ') }}
                </span>
            </mat-select-trigger>
            <mat-select-trigger *ngIf="!multiple">
                <span class="pl-4" [class]="textClasses">{{ selectedLabel }}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of options" [value]="item[valueKey]" class="-ml-2">
                {{ item[labelKey] }}
            </mat-option>
        </mat-select>
        <ng-content></ng-content>
    </div>
    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{ helper }}</span>
    <ng-container *ngIf="submitted">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-error-500">
            {{ errorMessage }}
        </span>
    </ng-container>
</div>
  
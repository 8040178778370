import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SignaturePadComponent, Step } from '@fc-lib';
import { BaseComponent } from '../base/base.component';
import { Waiver } from '../../models/waiver.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-waiver',
  templateUrl: './sign-waiver.component.html',
  styleUrl: './sign-waiver.component.scss'
})
export class SignWaiverComponent extends BaseComponent implements OnInit {
  @Input() waiverDetails: Waiver | undefined;
  @Input() guardianSignature = true;
  @Input() studentName = '';
  @Input() submitting = false;

  submitted = false;
  steps: Step[] = [
    { title: 'Your details', currentStep: false, completed: true },
    { title: 'Emergency contacts', currentStep: false, completed: true },
    { title: 'Sign a waiver', currentStep: true, completed: false },
  ]

  @ViewChild("signaturePad") signaturePadComponent: SignaturePadComponent | undefined;

  constructor(private fb: FormBuilder) { super(); }

  ngOnInit(): void {
    if (!this.guardianSignature && this.studentName) {
      this.signatureForm.get('signatureName')?.setValue(this.studentName);
    }
  }

  signatureForm = this.fb.group({
    signatureUrl: new FormControl<string>('', Validators.required),
    signatureName: new FormControl<string>('', Validators.required),
  })

  sign() {
    this.submitted = true;
    const signature = this.signaturePadComponent?.signaturePad.isEmpty();
    if (!signature) {
      this.signatureForm.get('signatureUrl')?.setValue(this.signaturePadComponent?.signaturePad.toDataURL()!);

      if (this.signatureForm.valid) {
        this.onNext.emit(this.signatureForm.value);
      }
    }
  }

  clear() {
    this.signaturePadComponent?.clearPad();
  }
}
